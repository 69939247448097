import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const EspaceParamsPage: FC<PageProps & EspaceProps & UserProps> = function ({
  espace,

  user,
}) {
  return <TemplateEspace espace={espace} user={user} />;
};

export default requireEspace(EspaceParamsPage);
